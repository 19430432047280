import React from "react"
import styled from "styled-components"
import { lightestGrey, darkAlt, Container } from "../../styles"
import { ContactBtn } from "../../lib"

export default function CTA() {
	return (
		<Container modifiers={["section", "full_width"]} background={lightestGrey}>
			<CtaInnerContainer as="div" modifiers={["nested"]}>
				<CTACopy>
					<p>Interested in collaborating?</p>
					<p>Get in touch.</p>
				</CTACopy>
				<CTABtnBox>
					<ContactBtn>Contact</ContactBtn>
				</CTABtnBox>
			</CtaInnerContainer>
		</Container>
	)
}

const CtaInnerContainer = styled(Container)`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 30px;

	& > div {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		padding-top: 24px;
		@media only screen and (min-width: 800px) {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
`

const CTACopy = styled.div`
	p:nth-of-type(1) {
		font-size: 1.75rem;
		margin: 0;
		margin-bottom: -6px;
	}

	p {
		font-size: 2.5rem;
		text-align: center;
		color: ${darkAlt};

		@media only screen and (min-width: 800px) {
			text-align: left;
		}
	}
`

const CTABtnBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 24px;
	width: 100%;

	@media only screen and (min-width: 800px) {
		width: auto;
	}
`
