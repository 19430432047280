import React from "react"
import { Hero } from "../../lib"

export default function AboutHeader() {
	return (
		<Hero
			line1="About Me. My Website"
			line2="Design & Development."
			para="Learn more about my approach to software development. Are you looking for someone to create software written in HTML, CSS, JavaScript, or Go?"
		/>
	)
}
