import React from "react"
import styled from "styled-components"
import { Container, Card, horizontalWhiteGreyAfterBG, SectionHeading, Para } from "../../styles"
import { rgba, darkSlate } from "../../styles/colors"

export default function AboutContent() {
	return (
		<OuterContainer modifiers={["full_width", "noBottomPad"]} background={horizontalWhiteGreyAfterBG}>
			<Card>
				<SectionHeading modifiers={["center"]}>Website Designer & Developer</SectionHeading>

				<div className="ct">
					<h3>Me</h3>
					<Para modifiers={["left"]}>
						I'm James Gleason. I write software with technologies based on HTML, CSS, JavaScript, and Go.
					</Para>
				</div>

				<div className="ct">
					<h3>I do</h3>
					<Para modifiers={["left"]}>
						Developer tooling & automation, server side rendered web applications, dynamically rendered static sites,
						graphql & rest APIs, design systems, component libraries, performance & accessibility tuning, application &
						site design, and various other software related things.
					</Para>
				</div>
			</Card>
		</OuterContainer>
	)
}

const OuterContainer = styled(Container)`
	position: relative;

	& > div {
		margin-top: -30px;
		padding: 4rem;

		@media only screen and (min-width: 700px) {
			transform: translateY(-60px);
			max-width: 730px;
			margin: 0 auto;
		}
	}

	.ct {
		position: relative;
		margin-top: 4rem;
	}

	h2 {
		font-size: 2.35rem;
		font-weight: 500;
	}

	h3 {
		position: absolute;
		top: -54px;
		left: -24px;
		font-size: 4rem;
		color: ${rgba(darkSlate, 0.17)};
	}
`
