import React from "react"
import Layout_main from "../layouts/Layout_main.js"
import AboutHeader from "../components/about/AboutHeader.js"
import AboutContent from "../components/about/AboutContent.js"
import CTA from "../components/contact/CTA.js"

export default function AboutPage({ location }) {
	return (
		<Layout_main location={location}>
			<AboutHeader />
			<AboutContent />
			<CTA />
		</Layout_main>
	)
}
